export default {
  prvafirma: {
    staging: 'https://int-oddin-prva-firma.staging.de-3.nsoft.cloud',
    production: 'https://int-oddin-prva-firma.staging.de-3.nsoft.cloud',
  },
  balkanbet: {
    staging: 'https://int-oddin-balkan-bet.staging.de-3.nsoft.cloud',
    production: 'https://int-oddin-balkan-bet.staging.de-3.nsoft.cloud',
  },
  simacommunication: {
    production: 'https://int-oddin-sima.de-3.nsoft.cloud',
  },
  betcoinsports: {
    production: 'https://int-oddin-betcoin.de-3.nsoft.cloud',
  },
  expo: {
    production: 'https://int-oddin-expo.de-3.nsoft.cloud',
  },
  victory: {
    staging: 'https://int-oddin-victory.staging.de-3.nsoft.cloud',
  },
  stanleybet: {
    staging: 'https://int-oddin-stanleybet.staging.de-3.nsoft.cloud',
    production: '',
  },
  maxbetro: {
    staging: 'https://int-oddin-max-bet-ro.staging.de-3.nsoft.cloud',
    production: 'https://int-oddin-max-bet-ro.de-3.nsoft.cloud',
  },
};
