export default {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  CLOSE_REOFFER_UI: 'CLOSE_REOFFER_UI',
  DISPLAY_EMAIL_VERIFICATION_ERROR: 'DISPLAY_EMAIL_VERIFICATION_ERROR',
  HANDLE_REOFFERED_TICKET: 'HANDLE_REOFFERED_TICKET',
  NOTIFICATION_REMOVE: 'NOTIFICATION_REMOVE',
  NOTIFICATION_REOFFER_ACTION: 'NOTIFICATION_REOFFER_ACTION',
  REMOVE_ALL_NOTIFICATIONS: 'REMOVE_ALL_NOTIFICATIONS',
  REMOVE_PENDING_TICKET: 'REMOVE_PENDING_TICKET',
  REMOVE_REOFFERED_TICKET: 'REMOVE_REOFFERED_TICKET',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_CPV_UUID: 'SET_CPV_UUID',
  SET_SOURCE_COMPANY_NAME: 'SET_SOURCE_COMPANY_NAME',
  SET_DEFAULT_TIME_VALUES: 'SET_DEFAULT_TIME_VALUES',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_SIR_WIDGETS: 'SET_SIR_WIDGETS',
  TOGGLE_CONNECTION_STATUS: 'TOGGLE_CONNECTION_STATUS',
  TOGGLE_REOFFER_POPUP: 'TOGGLE_REOFFER_POPUP',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  UPDATE_BALANCE: 'UPDATE_BALANCE',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_TOKEN: 'UPDATE_USER_TOKEN',
  UPDATE_MOBILE_TOGGLED_TOURNAMENTS: 'UPDATE_MOBILE_TOGGLED_TOURNAMENTS',
  UPDATE_BET_ASSIST_POPUP_ID: 'UPDATE_BET_ASSIST_POPUP_ID',
  MOBILE_TOGGLE_ALL_TOURNAMENTS: 'MOBILE_TOGGLE_ALL_TOURNAMENTS',
  MOBILE_CLEAR_ALL_TOGGLED_TOURNAMENTS: 'MOBILE_CLEAR_ALL_TOGGLED_TOURNAMENTS',
  MOBILE_COLLAPSE_ALL_TOURNAMENTS: 'MOBILE_COLLAPSE_ALL_TOURNAMENTS',
  SET_SCROLLBAR_WIDTH: 'SET_SCROLLBAR_WIDTH',
  SET_PREDEFINED_STAKE_STATE: 'SET_PREDEFINED_STAKE_STATE',
  SET_PREDEFINED_STAKE_SELECTED_VALUE: 'SET_PREDEFINED_STAKE_SELECTED_VALUE',
  SET_LIVE_MATCHES_COUNT: 'SET_LIVE_MATCHES_COUNT',
  ADD_REOFFERED_TICKET: 'ADD_REOFFERED_TICKET',
  UPDATE_ODDIN_ESPORT_DATA: 'UPDATE_ODDIN_ESPORT_DATA',
  SET_ODDIN_LIVE_MATCHES_COUNT: 'SET_ODDIN_LIVE_MATCHES_COUNT',
  SET_ODDIN_PREMATCH_MATCHES_COUNT: 'SET_ODDIN_PREMATCH_MATCHES_COUNT',
};
