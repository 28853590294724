import balkanbet from './balkanbet';
import bmk from './bmk';
import futurebet from './futurebet';
import hemingwaynew from './hemingwaynew';
import lutrijabosnaihercegovina from './lutrijabosnaihercegovina';
import simacommunication from './simacommunication';
import topbet from './topbet';
import isiparyaj from './isiparyaj';
import wettnet from './wettnet';
import playbetr from './playbetr';
import carrus from './carrus';
import betflip from './betflip';
import betcoinsportsxmb from './betcoinsportsxmb';
import victory from './victory';
import sportnaloterija from './sportnaloterija';
import avalon from './avalon';
import avalon2 from './avalon2';
import powerbet from './powerbet';
import baronbet from './baronbet';
import greenlotto from './greenlotto';
import olimp from './olimp';
import bethappy from './bethappyclient';
import betfirst from './betfirst';
import drewmanbetting from './drewmanbetting';
import stanleybet from './stanleybet';
import bugbet from './bugbet';
import prvafirma from './prvafirma';
import boulparyaj from './boulparyaj';
import maxbet from './maxbet';
import expo from './companyexpo';
import blackjackfun from './blackjackfun';
import pulsebet from './pulsebet';
import damagi from './damagi';
import betmen from './betmen';
import grandslotbet from './grandslotbet';
import pozeparyaj from './pozeparyaj';
import formulabih from './formulabih';
import reisbet from './reisbet';
import cryptocasino from './cryptocasino';
import excelbet from './excelbet';
import coinhero from './coinhero';
import betnest from './betnest';
import efbetserbia from './efbetserbia';
import bastabet from './bastabet';

export default {
  balkanbet,
  bmk,
  futurebet,
  isiparyaj,
  hemingwaynew,
  lutrijabosnaihercegovina,
  lutrijabihde: lutrijabosnaihercegovina,
  simacommunication,
  topbet,
  wettnet,
  playbetr,
  carrus,
  carrusint: carrus,
  luxembourglottery: carrus,
  betflip,
  betcoinsportsxmb,
  betcoinsports: betcoinsportsxmb,
  victory,
  sportnaloterija,
  lutrijaslovenije: sportnaloterija,
  victory1: victory,
  victory2: victory,
  victorybase: victory,
  victorynew: victory,
  victorymexico: victory,
  avalon,
  avalon2,
  powerbet,
  baronbet,
  greenlotto,
  greenlottoagent: greenlotto,
  olimp,
  bethappy,
  betfirst,
  betfirstnltest: betfirst,
  drewmanbetting,
  stanleybetint: stanleybet,
  stanleybet,
  stanleybetro2: stanleybet,
  bugbet,
  prvafirma,
  boulparyaj,
  maxbet,
  maxbetro: maxbet,
  maxbetromania: maxbet,
  expo,
  blackjackfun,
  pulsebet,
  damagi,
  betmen,
  grandslotbet,
  pozeparyaj,
  formulabih,
  formula: formulabih,
  formulaseven: formulabih,
  formulars: formulabih,
  reisbet,
  cryptocasino,
  liongaming: cryptocasino,
  excelbet,
  coinhero,
  betnest,
  efbetserbia,
  efbet: efbetserbia,
  bastabet,
};
